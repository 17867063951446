import React from "react";

const Modal = ({ isOpen, onClose, children, type }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-gray-700 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-3xl w-full relative">
        <button
          onClick={onClose}
          className="text-white bg-[#7b1f82] p-2 rounded-full w-20 hover:text-gray-700 float-right"
        >
          <i className="fa-sharp fa-solid fa-xmark text-2xl"></i>
        </button>

        {/* Conditional rendering based on the type */}
        <h2 className="text-xl font-bold mb-4 clear-both">
          {type === "refine" ? "" : ""}
        </h2>

        {/* Render dynamic content passed as children */}
        {children}
      </div>
    </div>
  );
};

export default Modal;
