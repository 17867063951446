import React, { useState } from "react";

function Refine({ suggestedQuestions, onSelectOption, onCloseModal}) {
  const [selectedIndexes, setSelectedIndexes] = useState({});

  // Function to handle option selection
  const handleOptionClick = (questionIndex, optionIndex) => {
    setSelectedIndexes((prev) => ({
      ...prev,
      [questionIndex]: optionIndex,
    }));
  };

  // Function to handle the Show Response button
  const handleShowResponse = () => {
    const selected = Object.entries(selectedIndexes).map(([questionIndex, optionIndex]) => ({
      selectedOption: suggestedQuestions[questionIndex].options[optionIndex],
    }));

    if (selected.length > 0) {
      const { selectedOption } = selected[0];
      onSelectOption(selectedOption); 
      onCloseModal();
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-8 text-center">Refine</h2>
      {suggestedQuestions.length > 0 && (
        <div>
          {suggestedQuestions.map((item, questionIndex) => (
            <div key={questionIndex} className="mb-6">
              <h3 className="text-lg font-semibold mb-4">
                {questionIndex + 1}. {item.question}
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {item.options.map((option, optionIndex) => (
                  <button
                    key={optionIndex}
                    className={`p-3 border rounded-lg text-sm font-medium transition-colors duration-200 ${
                      selectedIndexes[questionIndex] === optionIndex
                        ? "bg-[#f8d6e5] text-[#B03982] border-[#B03982]"
                        : "bg-transparent text-[#B03982] border-[#f8d6e5]"
                    } hover:bg-[#fce8f1]`}
                    onClick={() => handleOptionClick(questionIndex, optionIndex)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          ))}
          <div className="flex justify-center gap-4 mt-8">
            <button className="px-4 py-2 bg-transparent text-[#B03982] border border-[#B03982] rounded-3xl w-40 hover:bg-[#f8d6e5]">
              Edit Refine
            </button>
            <button
              className="px-4 py-2 bg-[#B03982] text-white rounded-3xl w-40"
              onClick={handleShowResponse} 
            >
              Show Response
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Refine;
