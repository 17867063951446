import React from "react";
// import footerlogo from "../assest/brand-logo.jpeg"

const Footer = () => {
  return (
    



<footer class="bg-gradient-to-b from-[#B03982] to-[#733C86] rounded-lg shadow m-4 dark:bg-gray-800">
    {/* <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span class="text-sm text-white sm:text-center dark:text-white">© 2024 <a href="https://flowbite.com/" class="hover:underline">NerdyBuddy™</a>. All Rights Reserved.
    </span>
    <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-white dark:text-white sm:mt-0">
        <li>
            <a href="#" class="hover:underline me-4 md:me-6">About</a>
        </li>
        <li>
            <a href="#" class="hover:underline me-4 md:me-6">Privacy Policy</a>
        </li>
        <li>
            <a href="#" class="hover:underline me-4 md:me-6">Licensing</a>
        </li>
        <li>
            <a href="#" class="hover:underline">Contact</a>
        </li>
    </ul>
    </div> */}
</footer>


  );
};

export default Footer;
