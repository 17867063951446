import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white">
      <div className="text-center">
        <h1 className="text-9xl font-extrabold bg-gradient-to-b from-[#B03982] to-[#733C86] text-transparent bg-clip-text">
          404
        </h1>
        <p className="text-2xl md:text-3xl font-semibold text-gray-800 mt-4">
          Oops! Page not found
        </p>
        <p className="text-gray-600 mt-2">
          The page you are looking for doesn't exist or has been moved.
        </p>
        <Link
          to="/"
          className="mt-6 inline-block px-6 py-3 text-white bg-gradient-to-b from-[#B03982] to-[#733C86] font-semibold rounded-full hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
        >
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
