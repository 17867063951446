import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const FeedbackForm = () => {
  const [formData, setFormData] = useState(null);
  const [responses, setResponses] = useState({});
  const [rating, setRating] = useState(1); 
  const [loading, setLoading] = useState(true); 
  const [suggestion, setSuggestion] = useState(""); 
  const navigate = useNavigate()

  // Fetch form data from the API
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_PORTAL_APP_BASE_URL}/feedback/form`,
          {
            method: "GET", 
            headers: { token: token,
              "Content-Type": "application/json" },
          }
        );
        const data = await response.json()
        setFormData(data.data[0]); 
      } catch (error) {
        console.error("Failed to fetch form data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchFormData();
  }, []);

  // Handle response change for each question
  const handleResponseChange = (questionId, answer) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: answer,
    }));
  };

  // Submit feedback
  const handleSubmit = async () => {
    if (!formData) return; 

    const formattedResponses = Object.keys(responses).map((questionId) => ({
      questionId: parseInt(questionId, 10),
      answer: responses[questionId],
    }));

    const submitData = {
      formId: formData.id,
      responses: formattedResponses,
      rating,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_PORTAL_APP_BASE_URL}/feedback/submitFeedback`, 
        submitData, 
        {
          headers: {
            token: token, 
            "Content-Type": "application/json", 
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert("Thank you for your feedback!");
        navigate("/"); 
      } else {
        console.error("Failed to submit feedback:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!formData) {
    return <p>Failed to load form data. Please try again later.</p>;
  }

  return (
    <div className="max-w-xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center mb-4">{formData.title}</h2>
      {/*Overall Rating */}
          <div className="mt-6">
      <p className="font-medium text-center mb-4">Overall Rating</p>
      <div className="flex justify-center space-x-3">
        {[1, 2, 3, 4, 5].map((value) => (
          <span
            key={value}
            className={`text-4xl cursor-pointer ${
              rating >= value ? "text-yellow-500" : "text-gray-300"
            }`}
            onClick={() => setRating(value)}
          >
            ★
          </span>
        ))}
      </div>
    </div>
       {/* Dynamically render questions */}
        <div className="mt-6">
        {formData.questions.map((question, index) => (
          <div key={index} className="mb-6">
            <p className="font-medium">{question.questionText}</p>
  
            {question.questionType === "multiple-choice" && (
              <div className="flex space-x-4">
                {question.options.map((option, idx) => (
                  <label key={idx} className="cursor-pointer">
                    <input
                      type="radio"
                      name={`question-${index}`}
                      value={option}
                      className="mr-2"
                      onChange={() => handleResponseChange(index + 1, option)}
                    />
                    {option}
                  </label>
                ))}
              </div>
            )}
  
            {question.questionType === "yes-no" && (
              <div className="flex space-x-4">
                {["Yes", "No"].map((option, idx) => (
                  <label key={idx} className="cursor-pointer">
                    <input
                      type="radio"
                      name={`question-${index}`}
                      value={option.toLowerCase()}
                      className="mr-2"
                      onChange={() => handleResponseChange(index + 1, option.toLowerCase())}
                    />
                    {option}
                  </label>
                ))}
              </div>
            )}
  
            {question.questionType === "text" && (
              <textarea
                className="w-full mt-2 p-2 border rounded-md"
                rows="3"
                placeholder="Enter your response..."
                onChange={(e) => handleResponseChange(index + 1, e.target.value)}
              ></textarea>
            )}
          </div>
        ))}
      </div>
  
      {/* Suggestions */}
      <div className="mt-6">
        <p className="font-medium">Suggestion (Optional)</p>
        <textarea
          className="w-full mt-2 p-2 border rounded-md"
          rows="3"
          placeholder="We would love to hear your suggestions..."
          value={suggestion}
          onChange={(e) => setSuggestion(e.target.value)}
        ></textarea>
      </div>
  
      {/* Submit Button */}
      <button
        className="w-full mt-6 px-4 py-2 bg-[#B03982] text-white rounded-lg hover:bg-[#9A316E] transition"
        onClick={handleSubmit}
      >
        Submit Feedback
      </button>
    </div>
  );
};
export default FeedbackForm;