import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // For page navigation
import axios from "axios"; // Axios to make API requests
import character from "../assest/cambine.jpg"; // Ensure this path is correct

const ForgotPassword = () => {
  const [email, setEmail] = useState(""); // State for email input
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [error, setError] = useState(""); // State for error messages
  const navigate = useNavigate(); // To navigate after success

  // Handle forgot password form submission
  const handleForgotPassword = () => {
    setIsLoading(true);
    setError(""); // Reset error message before submission

    axios
      .post(`${process.env.REACT_APP_PORTAL_APP_BASE_URL}/auth/forgetPassword`, {
        email: email,
      })
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        // On success, navigate back to the login page or show success message
        navigate("/reset-password");
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response?.data?.message || "An error occurred");
      });
  };

  return (
    <div className="min-h-screen flex">
      {/* Left Side - Character Image */}
      <div className="hidden md:flex w-1/2 items-center justify-center bg-white">
        <img
          src={character}
          alt="Forgot Password"
          className="w-[70%] h-auto object-contain"
        />
      </div>

      {/* Right Side - Forgot Password Form */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-8 bg-white">
        {/* Error Notification Bar */}
        {error && (
          <div
            className="fixed top-4 left-1/2 transform -translate-x-1/2 w-96 p-4 bg-red-600 text-white rounded-lg shadow-md flex items-center justify-between transition-all duration-500"
            style={{
              opacity: error ? 1 : 0,
              top: error ? "16px" : "-100px",
            }}
          >
            <span>{error}</span>
            <button
              className="ml-4 bg-red-800 rounded-full w-10 p-1 text-lg"
              onClick={() => setError("")}
            >
              ×
            </button>
          </div>
        )}
        <div className="w-full max-w-md">
          <h2 className="text-3xl font-bold text-center text-gray-700 mb-6">
            Forgot Password
          </h2>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-600"
            >
              Enter your Email Address
            </label>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 mt-2 border border-purple-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
          
          <button
            onClick={handleForgotPassword}
            className="w-full py-3 bg-gradient-to-b from-[#B03982] to-[#733C86] text-white font-semibold rounded-full hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send Reset Link"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
