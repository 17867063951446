
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import character from "../assest/character.png";
import Modal from "./Modal";
import Refine from "./refine";
import Doc from "./documentselector";

const Home = () => {
  const [question, setQuestion] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [references, setReferences] = useState([]);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visible
  const [modalType, setModalType] = useState(""); 
  const [documentDetails, setDocumentDetails] = useState([]);
  const navigate = useNavigate();

  const handleAsk = async () => {
    if (!question.trim()) return;
    setLoading(true);

    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_BASEURL}/query_response`,
        {
          method: "POST", 
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            query: question,
            chat_history: conversation.map((msg) => msg.message), // Include chat history
            language: "en",
          }), 
        }
      );

      if (!apiResponse.ok) {
        throw new Error(`Server responded with status ${apiResponse.status}`);
      }

      const data = await apiResponse.json();
      console.log("data:",data)

      setResponse(data.answer);
      setSuggestedQuestions(data.suggested_questions || []);
      setReferences(
        Array.isArray(data.references) ? data.references : [data.references]
      );
      console.log(data.document_details);
      setDocumentDetails(data.document_details || []); 

      setConversation((prev) => [
        ...prev,
        { id: `user-${Date.now()}`, sender: "user", message: question },
        { id: `ai-${Date.now()}`, sender: "ai", message: data.answer },
      ]);

      setCurrentQuestion(question);
      setQuestion("");
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToReferences = () => {
    navigate("/references", { state: { references } }); 
  };
  
  const handleRefineSuggestions = () => {
    setModalType("refine"); 
    setIsModalOpen(true); 
  };

  const handleEditDoc = () => {
    setModalType("editDoc"); 
    setIsModalOpen(true); 
  };

  const handleSelectOption = async (selectedOption) => {
    setCurrentQuestion(selectedOption); // Update the current question
    setLoading(true);
    setIsModalOpen(false);

    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_BASEURL}/query_response`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            query: selectedOption,
            chat_history: conversation.map((msg) => msg.message),
            language: "en",
          }),
        }
      );

      if (!apiResponse.ok) {
        throw new Error(`Server responded with status ${apiResponse.status}`);
      }

      const data = await apiResponse.json();

      setResponse(data.answer);
      setReferences(
        Array.isArray(data.references) ? data.references : [data.references]
      );
      setDocumentDetails(data.document_details || []);

      setConversation((prev) => [
        ...prev,
        { id: `user-${Date.now()}`, sender: "user", message: selectedOption },
        { id: `ai-${Date.now()}`, sender: "ai", message: data.answer },
      ]);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setLoading(false);
      setIsModalOpen(false); // Close the modal
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Main Content */}
      <div className="flex-grow"></div>

      {/* Footer Content */}
      <div className="sticky bottom-0 flex flex-col lg:flex-row items-end justify-between w-full bg-gray-50 p-6 gap-6 z-100 bg-transparent">
        {/* Character Image */}
        <div className="w-[15rem] h-[26rem]">
          <img
            src={character}
            alt="Character"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Input and Detailed Section */}
        <div className="flex flex-1 flex-col md:flex-row items-end justify-between w-full gap-4">
          {/* Input Section */}
          <div className="flex-1">
            {/* Greeting Card */}
            <div className="w-full p-4 bg-gray-100 rounded-lg shadow-md mb-4 relative bottom-80">
              <p className="text-lg font-semibold text-gray-800 mb-2">
                Hi! I am here to answer your queries.
              </p>
            </div>
            {loading ? (
        <div className="w-full p-4 bg-gray-100 rounded-lg shadow-md mb-4 relative bottom-80 text-center">
          <p className="text-gray-700">Loading...</p>
        </div>
      ) : (
        response && (
          <div className="w-full p-4 bg-gray-100 rounded-lg shadow-md mb-4 relative bottom-80">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              Q: {currentQuestion}
            </h3>
            <p className="text-gray-700">{response}</p>
          </div>
        )
      )}  
           <div className="flex justify-center">
            <div className="mt-4 mb-5">
              {response && (
                <>
                  <button
                    onClick={handleNavigateToReferences}
                    className="px-4 py-2 bg-transparent border-2 border-[#B03982] text-[#B03982] rounded-3xl mr-2 w-40"
                  >
                    Show References
                  </button>
                  <button
                    onClick={handleRefineSuggestions}
                    className="px-4 py-2 bg-[#B03982] text-white rounded-3xl mr-2 w-40"
                  >
                    Refine it
                  </button>
                  <button
                    onClick={handleEditDoc}
                    className="px-4 py-2 bg-[#B03982] text-white rounded-3xl w-40"
                  >
                    Edit Doc Type
                  </button>
                </>
              )}
            </div>
          </div>
            <div className="relative">
              <input
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Ask Buddy..."
                className="w-full h-12 p-4 pr-12 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B03982]"
              />
              <button
                onClick={handleAsk}
                disabled={!question.trim() || loading}
                className={`absolute right-2 top-2 px-4 py-1.5 rounded-lg text-white font-semibold ${
                  question.trim() && !loading
                    ? "bg-[#B03982] hover:bg-[#B03982]"
                    : "bg-gray-300 cursor-not-allowed"
                }`}
              >
                <i className="fa-sharp fa-solid fa-paper-plane"></i>
              </button>
            </div>
          </div>
      {/* Detailed View */}
        <div className="bg-pink-100 shadow-md rounded-lg p-6 w-full md:w-1/3">
          <h2 className="text-xl font-bold text-gray-800 mb-4">Detailed View</h2>

          {conversation.length > 0 ? (
            <div className="overflow-y-auto max-h-[300px]">
              {conversation.map((msg, index) => (
                <div key={msg.id} className="mb-4">
                  <p className="text-lg font-semibold text-gray-800 inline">
                    {msg.sender === 'user' ? 'Q:' : ''}
                  </p>
                  <p className="text-gray-700 inline font-bold">{msg.sender === 'user' ? msg.message : ''}</p>
                  <p className="text-gray-700">{msg.sender !== 'user' ? msg.message : ''}</p>
                  </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500">Ask a question to see the detailed response here.</p>
          )}
        </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        type={modalType} 
      >
        {modalType === "refine" ? <Refine suggestedQuestions={suggestedQuestions} onSelectOption={handleSelectOption} onCloseModal={() => setIsModalOpen(false)}/> : <Doc documentDetails={documentDetails} onCloseModal={()=>setIsModalOpen(false)}/>}
      </Modal>
    </div>
  );
};

export default Home;
