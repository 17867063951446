import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; // Redux hooks
import { loginUser } from "../features/auth/authSlice"; // Thunk
import { useNavigate } from "react-router-dom";
import character from "../assest/cambine.jpg"; // Ensure this path is correct

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // State for Remember Me checkbox
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  // Local state to manage error for notification bar
  const [localError, setLocalError] = useState(null);

  const handleLogin = () => {
    dispatch(loginUser({ email, password, rememberMe }))
      .unwrap()
      .then(() => {
        // Redirect to home after successful login
        navigate("/");
      })
      .catch((err) => {
        if (err.message === "First login. Please change password.") {
          navigate("/change-password");
        }
        console.error("Login failed:", err);
        // Error will be handled by the useEffect below
      });
  };

  // Update localError when error from Redux changes
  useEffect(() => {
    if (error) {
      setLocalError(
        typeof error === "object" && error.message ? error.message : error
      );
    }
  }, [error]);

  // Automatically hide the error message after 3 seconds
  useEffect(() => {
    if (localError) {
      const timer = setTimeout(() => {
        setLocalError(null); // Clear error after 3 seconds
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [localError]);

  return (
    <div className="min-h-screen flex">
      {/* Left Side - Character Image */}
      <div className="hidden md:flex w-1/2 items-center justify-center bg-white">
        <img
          src={character}
          alt="Login"
          className="w-[70%] h-auto object-contain"
        />
      </div>

      {/* Right Side - Login Form */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-8 bg-white">
        {/* Error Notification Bar */}
        {localError && (
          <div
            className="fixed top-4 left-1/2 transform -translate-x-1/2 w-96 p-4 bg-red-600 text-white rounded-lg shadow-md flex items-center justify-between transition-all duration-500"
            style={{
              opacity: localError ? 1 : 0,
              top: localError ? "16px" : "-100px",
            }}
          >
            <span>
              {typeof localError === "string"
                ? localError
                : JSON.stringify(localError)}
            </span>
            <button
              className="ml-4 bg-red-800 rounded-full w-10 p-1 text-lg"
              onClick={() => setLocalError(null)}
            >
              ×
            </button>
          </div>
        )}
        <div className="w-full max-w-md">
          <h2 className="text-3xl font-bold text-center text-gray-700 mb-6">
            Login
          </h2>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-600"
            >
              Email Address
            </label>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 mt-2 border border-purple-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              Password
            </label>
            <input
              id="password"
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 mt-2 border border-purple-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>

          {/* Remember Me and Forgot Password */}
          <div className="flex items-center justify-between mb-4">
            {/* Remember Me Checkbox */}
            <div className="flex items-center">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                className="mr-2"
              />
              <label htmlFor="rememberMe" className="text-sm text-gray-600">
                Remember Me
              </label>
            </div>

            {/* Forgot Password Link */}
            <div>
              <a
                href="/forgot-password"
                className="text-sm text-purple-500 hover:text-purple-700"
              >
                Forgot Password?
              </a>
            </div>
          </div>

          <button
            onClick={handleLogin}
            className="w-full py-3 bg-gradient-to-b from-[#B03982] to-[#733C86] text-white font-semibold rounded-full hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
            disabled={isLoading}
          >
            {isLoading ? "Logging in..." : "Login"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
