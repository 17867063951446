import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ReferencesPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const references = location.state?.references || [];

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <h1 className="text-3xl font-bold text-center mb-8">References</h1>
      {references.length > 0 ? (
        <ul className="list-disc  ml-5">
          {references.map((ref, index) => (
            <li key={index} className="text-gray-800 mb-2">
              {ref}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500 text-center">No references available.</p>
      )}
      <div className="flex justify-center mt-8 gap-4">
      <button
          onClick={() => navigate('/')} // Go back to the home screen
          className="px-4 py-2 bg-[#B03982] text-white rounded-lg"
        >
          Ask Another Question
        </button>
        <button
          onClick={() => navigate('/feedback')} // Go to the feedback form
          className="px-4 py-2 bg-[#B03982] text-white rounded-lg"
        >
          Share Feedback
        </button>
        <button
          onClick={() => navigate(-1)} // Go back to the previous page
          className="px-4 py-2 bg-[#B03982] text-white rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ReferencesPage;