import React, { useState } from "react";
import logo from "../assest/brand-logo.png"
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleSessionHistoryClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false); // Stop loading after a certain time (simulate API call)
    }, 2000);
  };

  const handleSignOut = () => {
    // Remove user data and token from localStorage
    localStorage.removeItem("user");
    localStorage.removeItem("token");

    // Redirect to the login page
    navigate("/login");
  };

  return (
    <>
      {/* Header */}
      <header className="header bg-white p-4 flex items-center justify-between">
        <div
          className="header_toggle text-black text-2xl cursor-pointer"
        >
          {/* <i className="bx bx-menu text-black-500" id="header-toggle"></i> */}
        </div>
        <div className="header_img">
          <img
            src="https://i.imgur.com/hczKIze.jpg"
            alt="User"
            className="w-48 h-10 rounded-full"
          />
        </div>
      </header>

      {/* Sidebar */}
      <div className={`l-navbar ${isOpen ? "show" : ""}`} id="nav-bar">
      <nav className="nav flex flex-col h-full">
      <div className="p-4 flex items-center justify-between" style={{ minHeight: '64px' }}>
      <div
          className="header_toggle text-black text-2xl cursor-pointer" style={{ color: 'black', marginTop: '10px', marginRight: '10px', marginLeft: '5px' }}
          onClick={toggleSidebar}
        >
          <i className="bx bx-menu text-black-500" id="header-toggle"></i>
        </div>
      <div className="flex items-center">
        <img
          src={logo}
          alt="Logo"
          className="w-auto h-auto mr-3"
          style={{ visibility: isOpen ? 'visible' : 'hidden' }}
        />
      </div>
    </div>

        {/* Sidebar Menu */}
        <div className="nav_list flex-grow">
          <button
            className="nav_link flex items-center px-4 py-2 text-black hover:bg-red-200 rounded-full"
          >
            <i className="bx bx-plus nav_icon text-xl mr-2"></i>
            <span className="nav_name text-sm">Start New Session
            </span>
          </button>
          <a
          href="/doc"
          className="nav_link flex items-center px-4 py-2 text-black hover:bg-red-200 rounded-full"
          onClick={handleSessionHistoryClick} // Trigger loading when clicked
        >
          {/* Circular Reload Icon (Animated) */}
          <i
            className={`bx bx-history nav_icon text-xl mr-3 ${loading ? "loading" : ""}`}
          ></i>
          <span className="nav_name text-sm ">Session History</span>
        </a>
          <button
            className="nav_link flex items-center px-4 py-2 text-black hover:bg-red-200 rounded-full"
          >
            <i className="bx bx-message-square-detail nav_icon text-xl mr-3"></i>
            <span className="nav_name text-sm">Training and certification</span>
          </button>
          <button
            className="nav_link flex items-center px-4 py-2 text-black hover:bg-red-200 rounded-full"
          >
            <i className="bx bx-cog nav_icon text-xl mr-3"></i>
            <span className="nav_name text-sm">Setting</span>
          </button>
          {/* <a
            href="#"
            className="nav_link flex items-center px-4 py-2 text-black hover:bg-gray-100"
          >
            <i className="bx bx-folder nav_icon text-xl mr-3"></i>
            <span className="nav_name">Files</span>
          </a>
          <a
            href="#"
            className="nav_link flex items-center px-4 py-2 text-black hover:bg-gray-100"
          >
            <i className="bx bx-bar-chart-alt-2 nav_icon text-xl mr-3"></i>
            <span className="nav_name">Stats</span>
          </a> */}
        </div>

        {/* Sign Out Link */}
        <button
          onClick={handleSignOut}
          className="nav_link flex items-center px-4 py-2 text-black hover:bg-gray-100"
        >
          <i className="bx bx-log-out nav_icon text-xl mr-3"></i>
          <span className="nav_name">SignOut</span>
        </button>
        <button
          className="nav_link flex items-center px-4 py-2 text-black hover:bg-gray-100"
        >
            <i className="bx bx-globe nav_icon text-xl mr-3"></i>
            <span className="nav_name">English</span>
        </button>
      </nav>
      <style jsx>{`
        .loading {
          animation: rotate 1s infinite linear;
        }

        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
      {/* Sidebar Overlay */}
      {isOpen && (
        <div
          className="overlay fixed inset-0 bg-black bg-opacity-0"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default Header;
