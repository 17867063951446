import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header"; // Header Component
import Footer from "./components/Footer"; // Footer Component
import Home from "./pages/Home"; // Home Page
import About from "./pages/About"; // About Page
import NotFound from "./pages/NotFound"; // Not Found Page
import { AppContextProvider, AppContext } from "./context/AppContext"; // Context for global state
import Login from "./pages/Login"; // Login Page
import ForgotPassword from "./pages/ForgotPassword"; // forgot Page
import DocumentTypeForm from "./pages/documentselector"; // Document Type Selector Page
import FeedbackForm from "./context/Feedback"; // Feedback Form
import PolicyForm from "./pages/Policyform"; // Policy Form
import ProtectedRoute from "./components/ProtectedRoute"; // Protected Route Component
import ResetPassword from "./pages/resetPassword";
import ResetPass from "./pages/resetPassByUniqId";
import Refine from "./pages/refine"
import ReferencesPage from "./pages/References";

function App() {
  const { setUserData, setLoading, userData } = useContext(AppContext);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      const storedUserData = localStorage.getItem("user");
      const storedToken = localStorage.getItem("token");

      // Check if both user and token are present
      if (storedUserData && storedToken) {
        try {
          setUserData(JSON.parse(storedUserData)); // Parse and set user data
        } catch (error) {
          console.error("Error parsing user data:", error);
          setUserData(null); // Reset if parsing fails
        }
      } else {
        setUserData(null); // No user data or token found
      }
      setLoading(false);
    }, 1000);
  }, [setUserData, setLoading]);

  return (
    <AppContextProvider>
      <Router>
        <AppContent userData={userData} />
      </Router>
    </AppContextProvider>
  );
}

function AppContent({ userData }) {
  const location = useLocation(); // Get the current location (path)

  return (
    <div className="App">
      {/* Conditionally render Header if userData exists and the path is not /login */}
      {!(
        location.pathname === "/login" ||
        location.pathname === "/change-password" ||
        location.pathname === "/forgot-password" ||
        location.pathname === "/reset-password"
      ) && <Header />}

      <main>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/change-password" element={<ResetPassword />} />
          <Route path="/reset-password" element={<ResetPass />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/about" element={<About />} />

          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute userData={userData}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/references"
            element={
              <ProtectedRoute userData={userData}>
                <ReferencesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/doc"
            element={
              <ProtectedRoute userData={userData}>
                <DocumentTypeForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/feedback"
            element={
              <ProtectedRoute userData={userData}>
                <FeedbackForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/policy"
            element={
              <ProtectedRoute userData={userData}>
                <PolicyForm />
              </ProtectedRoute>
            }
          />
          {/* refine it */}
          <Route
            path="/refine"
            element={
              <ProtectedRoute userData={userData}>
                <Refine />
              </ProtectedRoute>
            }
          />
          {/* 404 Page */}
          <Route
            path="*"
            element={
              <ProtectedRoute userData={userData}>
                <NotFound />
              </ProtectedRoute>
            }
          />
          

          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </main>

      {/* Conditionally render Footer if userData exists and the path is not /login */}
      {userData &&
        !(
          location.pathname === "/login" ||
          location.pathname === "/change-password"
        ) && <Footer />}
    </div>
  );
}

export default App;
