import React, { useState } from "react";

const PolicyForm = () => {
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [selectedImplementation, setSelectedImplementation] = useState(null);

  const policies = [
    "Policy of loan related to xyz",
    "Policy of loan related to xyz",
    "Policy of loan related to xyz",
    "Policy of loan related to xyz",
  ];

  const implementations = ["ABC", "ABC", "ABC", "ABC"];

  return (
    <div className="min-h-screen bg-gray-50 flex justify-center items-center">
      <div className="w-full max-w-3xl bg-white p-6 shadow-md rounded-lg">
        <h2 className="text-2xl font-semibold text-center text-black mb-6">Refine</h2>

        {/* Question 1 */}
        <div className="mb-6">
          <p className="text-lg font-medium mb-4">1. What are the pre-requisites of loan policy?</p>
          <div className="grid grid-cols-2 gap-4">
            {policies.map((policy, index) => (
              <button
                key={index}
                className={`border-2 rounded-lg px-4 py-2 text-center ${
                  selectedPolicy === index
                    ? "bg-pink-600 text-white border-pink-600"
                    : "border-pink-600 text-pink-600 hover:bg-pink-100"
                }`}
                onClick={() => setSelectedPolicy(index)}
              >
                {policy}
              </button>
            ))}
          </div>
        </div>

        {/* Question 2 */}
        <div className="mb-6">
          <p className="text-lg font-medium mb-4">2. How the policy of xyz be implemented?</p>
          <div className="grid grid-cols-2 gap-4">
            {implementations.map((implementation, index) => (
              <button
                key={index}
                className={`border-2 rounded-lg px-4 py-2 text-center ${
                  selectedImplementation === index
                    ? "bg-pink-600 text-white border-pink-600"
                    : "border-pink-600 text-pink-600 hover:bg-pink-100"
                }`}
                onClick={() => setSelectedImplementation(index)}
              >
                {implementation}
              </button>
            ))}
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-between mt-6">
          <button className="px-4 py-2 rounded-lg bg-[#733C86] text-white font-medium hover:bg-pink-300">
            Edit Refine
          </button>
          <button className="px-4 py-2 rounded-lg bg-[#733C86] text-white font-medium hover:bg-pink-300">
            Show Response
          </button>
          <button className="px-4 py-2 rounded-lg bg-[#733C86] text-white font-medium hover:bg-pink-700">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PolicyForm;
